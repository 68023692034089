import React from "react";
import './styles/dashboardPage.scss';
import Dashboard from "../../components/Dashboard/Dashboard";

export default class DashboardPage extends React.Component {
  render() {
    return (
      <div>
        <Dashboard/>
      </div>
    )
  }
}