import React, { Suspense, StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from "@sentry/react";
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import history from "./utils/history";

import { createStore } from "./store";
import {Router} from "react-router";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN
});

if (process.env.REACT_APP_APP_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
}

export const APP_ENV = process.env.REACT_APP_APP_ENV;

(async () => {
    const StoreProvider = await createStore();

    ReactDOM.render(
        <Suspense fallback={<div>Loading...</div>}>
            <StoreProvider>
                <StrictMode>
                  <Router history={history}>
                    <App />
                  </Router>
                </StrictMode>
            </StoreProvider>
        </Suspense>,
      document.getElementById('root')
    );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
