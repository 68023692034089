import './App.css';
import links from "./constants/links";
import {Switch, Route} from "react-router";

import SomethingWentWrongPage from "./views/SomethingWentWrongPage/SomethingWentWrongPage";
import NotFoundPage from "./views/NotFoundPage/NotFoundPage";
import DashboardPage from "./views/DashboardPage/DashboardPage";

function App() {
  const {pages: {
    dashboard, somethingWentWrong
  }} = links;

  return (
    <div>
      <Switch>
        <Route exact={true} path={dashboard.path} component={DashboardPage}/>
        <Route exact={true} path={somethingWentWrong.path} component={SomethingWentWrongPage}/>
        <Route path="*" component={NotFoundPage}/>
      </Switch>
    </div>
  );
}

export default App;
